var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.academicPeriods)?_c('div',{attrs:{"id":"academicPeriodsClone"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},_vm._l((_vm.academicPeriods),function(level2,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-text',[_c('v-row',{staticClass:"form-item"},[_c('v-col',{staticStyle:{"margin-top":"15px !important"},attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("Name"))+" ")])]),_c('v-col',{staticStyle:{"margin-top":"15px !important"},attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"id":"academic-year-name","rules":_vm.requiredRuleName,"solo":"","dense":""},model:{value:(level2.translations[1].name),callback:function ($$v) {_vm.$set(level2.translations[1], "name", $$v)},expression:"level2.translations[1].name"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("Short Name")))])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"id":"academic-year-name","rules":_vm.requiredRuleShortName,"solo":"","dense":""},model:{value:(level2.short_name),callback:function ($$v) {_vm.$set(level2, "short_name", $$v)},expression:"level2.short_name"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("Start Date")))])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"id":level2.start_date_id,"rules":[
                    _vm.startDateRule(level2.start_date, level2.end_date),
                    _vm.requiredRules.required
                  ],"append-icon":"calendar_today","solo":"","dense":"","autocomplete":"off"},on:{"keydown":function($event){$event.preventDefault();}},model:{value:(level2.start_date),callback:function ($$v) {_vm.$set(level2, "start_date", $$v)},expression:"level2.start_date"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("End Date")))])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"id":level2.end_date_id,"rules":[
                    _vm.startDateRule(level2.start_date, level2.end_date),
                    _vm.requiredRules.required
                  ],"append-icon":"calendar_today","solo":"","dense":"","autocomplete":"off"},on:{"keydown":function($event){$event.preventDefault();}},model:{value:(level2.end_date),callback:function ($$v) {_vm.$set(level2, "end_date", $$v)},expression:"level2.end_date"}})],1)],1)],1)],1)],1),_vm._l((level2.grandchildren),function(level3,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-text',[_c('v-row',{staticClass:"form-item"},[_c('v-col',{staticStyle:{"margin-top":"15px !important"},attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("Name")))])]),_c('v-col',{staticStyle:{"margin-top":"15px !important"},attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"id":"academic-year-name","rules":_vm.requiredRuleName,"solo":"","dense":""},model:{value:(level3.translations[1].name),callback:function ($$v) {_vm.$set(level3.translations[1], "name", $$v)},expression:"level3.translations[1].name"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("Short Name")))])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"id":"academic-year-name","rules":_vm.requiredRuleShortName,"solo":"","dense":""},model:{value:(level3.short_name),callback:function ($$v) {_vm.$set(level3, "short_name", $$v)},expression:"level3.short_name"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("Start Date")))])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"id":level3.start_date_id,"rules":[
                    _vm.startDateRule(level3.start_date, level3.end_date),
                    _vm.requiredRules.required
                  ],"append-icon":"calendar_today","solo":"","dense":"","autocomplete":"off"},on:{"keydown":function($event){$event.preventDefault();}},model:{value:(level3.start_date),callback:function ($$v) {_vm.$set(level3, "start_date", $$v)},expression:"level3.start_date"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"Academic-Year-name"}},[_vm._v(" "+_vm._s(_vm.$t("End Date")))])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"id":level3.end_date_id,"rules":[
                    _vm.startDateRule(level3.start_date, level3.end_date),
                    _vm.requiredRules.required
                  ],"append-icon":"calendar_today","solo":"","dense":"","autocomplete":"off"},on:{"keydown":function($event){$event.preventDefault();}},model:{value:(level3.end_date),callback:function ($$v) {_vm.$set(level3, "end_date", $$v)},expression:"level3.end_date"}})],1)],1)],1)],1)],1)})],2)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[(_vm.academicPeriods.length == 0)?_c('v-alert',{staticClass:"info_alert"},[_vm._v(" "+_vm._s(_vm.$t("There are no academic periods for this year"))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"6","md":"6"}},[_c('button',{staticClass:"ui-btn submit",on:{"click":_vm.emitToParentPrevious}},[_vm._v(" "+_vm._s(_vm.$t("Previous"))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6","md":"6"}},[_c('button',{staticClass:"ui-btn submit",on:{"click":_vm.emitToParentNext}},[_vm._v(" "+_vm._s(_vm.$t("Next"))+" ")])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }