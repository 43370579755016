<template>
  <div id="classes">
    <v-form ref="form" v-model="valid" v-if="grades.length != 0">
      <v-data-table
        :headers="headers"
        :items="classes"
        :no-data-text="$t('No classes available')"
        class="elevation-1 level1 newChild"
        hide-default-footer
      >
        <template #item.students_count="{item}">
          <v-text-field
            min="1"
            v-on:keydown.109.capture.prevent.stop
            v-on:keydown.189.capture.prevent.stop
            v-model.trim="item.students_count"
            solo
            @focus="onFocus()"
          ></v-text-field>
          <!-- <v-checkbox v-model="item.is_active"></v-checkbox> -->
        </template>

        <template #item.teacher="{item}">
          <v-select
            :items="teachers"
            item-text="name"
            item-value="id"
            v-model.trim="item.teacher_id"
            @focus="onFocus()"
            solo
          >
          </v-select>
        </template>
        <template #item.actions="{item}">
          <v-icon @click="deleteItem(item)">delete</v-icon>
        </template>
      </v-data-table>
    </v-form>

    <div class="icon-sec text-center" v-if="grades.length != 0">
      <v-icon
        large
        title="Add new class"
        class="mr-2 main-color"
        @click="openDialog"
        >add_circle</v-icon
      >
    </div>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Add New Class") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="modalForm" v-model="modalValid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Class Name") }}</label>
                  <v-text-field
                    v-model.trim="selectedClasses.name"
                    :rules="requiredRuleName"
                    solo
                    @focus="onFocus()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Capacity") }}</label>
                  <v-text-field
                    min="1"
                    v-on:keydown.109.capture.prevent.stop
                    v-on:keydown.189.capture.prevent.stop
                    v-model.trim="selectedClasses.students_count"
                    :rules="capacityRule"
                    solo
                    @focus="onFocus()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Grade") }}</label>
                  <v-select
                    :items="grades"
                    item-text="grade_name"
                    item-value="id"
                    :rules="requiredRules"
                    v-model.trim="selectedClasses.academic_grade_id"
                    @focus="onFocus()"
                    solo
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Head Teacher") }}</label>
                  <v-select
                    :items="teachers"
                    item-text="name"
                    item-value="id"
                    v-model.trim="selectedClasses.teacher_id"
                    @focus="onFocus()"
                    solo
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="modal-btn-cancel" @click="close">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn class="modal-btn-save" text @click="save">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" md="10">
        <v-alert
          v-if="grades.length == 0 && loadGrades == true"
          class="info_alert"
        >
          {{ $t("There is no grades for this year") }}
        </v-alert>
      </v-col>
      <v-col cols="6" md="6" class="text-left">
        <button class="ui-btn submit" @click="emitToParentPrevious">
          {{ $t("Previous") }}
        </button>
      </v-col>
      <v-col cols="6" md="6" class="text-right">
        <button class="ui-btn submit" @click="emitToParentNext">
          {{ $t("Next") }}
        </button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Classes",
  props: {
    clonedId: String,
    clonedObject: Array,
    id: Number,
    previous: Boolean,
    dataSaved: Boolean,
    visited: Boolean, // this to check if component visited or not
    userData: Array
  },
  data() {
    return {
      modalValid: true,
      teachers: [],
      requiredRules: [v => !!v || "This field is required"],
      valid: true,
      /* snackbar data */
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      multiline: true,
      updated: false,
      deleted: false,
      gradeError: false,

      headers: [
        {
          text: this.$i18n.t("Name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$i18n.t("Capacity"),
          value: "students_count",
          sortable: false
        },
        {
          text: this.$i18n.t("Grade"),
          value: "academic_grade",
          sortable: false
        },
        {
          text: this.$i18n.t("Head Teacher"),
          value: "teacher",
          sortable: false
        },
        { text: this.$i18n.t("Action"), value: "actions", sortable: false }
      ],
      grades: [],
      selectedGrades: [],
      /* add item model */
      dialog: false,
      selectedClasses: {
        name: "",
        code: "",
        students_count: "",
        academic_grade_id: "",
        teacher_id: "",
        academic_grade: "",
        teacher: ""
      },
      classes: [],
      requiredRuleName: [
        v => !!v || this.$i18n.t("This field is required"),
        v => v.length <= 50 || "Name must be less than 50 characters"
      ],
      loadGrades: false,
      capacityRule: [
        v => !!v || this.$i18n.t("This field is required"),
        v =>
          (v && /^\d+$/.test(v)) ||
          this.$i18n.t("This field only accept numbers")
      ]
    };
  },
  watch: {
    clonedObject: {
      handler() {
        if (this.visited == true) {
          this.classes = this.userData;
        } else {
          this.classes = this.clonedObject;
        }
        this.getGrades();
        this.getTeachers();
      }
    }
  },
  methods: {
    // capacityRule(val) {
    //   // console.log(/^\d+$/.test(val));
    //   if (val != "" && val != "undefiend") {
    //     if (!/^\d+$/.test(val)) {
    //       return "This field only accept numbers";
    //     }
    //   } else {
    //     return true;
    //   }
    // },
    emitToParentNext() {
      if (this.valid == true) {
        var nextObject = {
          tab: 5,
          next: true,
          submittedObject: this.classes,
          dataSaved: this.dataSaved
        };
        this.$emit("childToParentNext", nextObject);
      } else {
        this.$refs.form.validate();
      }
    },
    emitToParentPrevious() {
      var previousObject = {
        tab: 3,
        userObject: this.classes
      };

      this.$emit("childToParentPrevious", previousObject);
    },

    getGrades() {
      axios
        .get(this.getApiUrl + "/school/getAcademicPeriodGrade/" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.grades = response.data.data;
          this.filterClasses();
          this.loadGrades = true;
        });
    },

    getTeachers() {
      axios
        .get(this.getApiUrl + "/users?role=Teacher", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.teachers = response.data.data;
        });
    },

    deleteItem(item) {
      if (this.dataSaved == true) {
        axios
          .get(this.getApiUrl + "/classes/destroy/" + item.id, {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            if (response.data.status.error == false) {
              const index = this.classes.indexOf(item);
              this.classes.splice(index, 1);
            }
          });
      } else {
        const index = this.classes.indexOf(item);
        this.classes.splice(index, 1);
      }
    },
    openDialog() {
      this.dialog = true;
      // this.getSelectedGradesData();
    },
    save() {
      if (this.modalValid == true) {
        let classes = this.classes;
        let grades = this.grades;
        let teachers = this.teachers;

        let gradeName = grades.find(
          grade => grade.id == this.selectedClasses.academic_grade_id
        ).grade_name;

        if (this.selectedClasses.teacher_id) {
          let teacherName = teachers.find(
            teacher => teacher.id == this.selectedClasses.teacher_id
          ).name;

          this.selectedClasses.teacher = teacherName;
        }

        this.selectedClasses.academic_grade = gradeName;

        classes.push(this.selectedClasses);
        this.dialog = false;

        this.selectedClasses = {
          name: "",
          code: "",
          students_count: "",
          academic_grade_id: "",
          teacher_id: "",
          academic_grade: "",
          teacher: ""
        };

        this.$refs.modalForm.resetValidation();
      } else {
        this.$refs.modalForm.validate();
      }
    },
    close() {
      this.selectedClasses = {
        name: "",
        code: "",
        students_count: "",
        academic_grade_id: "",
        teacher_id: "",
        academic_grade: "",
        teacher: ""
      };
      this.dialog = false;
      this.$refs.modalForm.resetValidation();
    },
    onFocus() {},

    filterClasses() {
      let classes = this.classes;
      let grades = this.grades;
      let gradesNames = [];

      grades.forEach(function(grade) {
        gradesNames.push(grade.grade_name);
      });

      // when user delete data from selected
      classes.forEach(function(row) {
        if (gradesNames.includes(row.academic_grade) == false) {
          classes = classes.filter(function(classItem) {
            return classItem.academic_grade != row.academic_grade;
          });
        }
      });

      this.classes = classes;

      console.log(classes);
    }
  },
  mounted() {
    if (this.visited == true) {
      this.classes = this.userData;
    } else {
      this.classes = this.clonedObject;
    }
    this.getGrades();
    this.getTeachers();
    // this.getSelectedGradesData();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

#classes {
  margin-top: 100px !important;
}

.ui-btn {
  margin: 0 10px 0;
}

label {
  margin-top: 7px !important;
}

.info_alert {
  margin-left: 23%;
  margin-top: 10%;
  margin-bottom: 13%;
  color: rgba(0, 0, 0, 0.38);
}
</style>
