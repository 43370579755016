<template>
  <div id="generalInfo" class="" v-if="generalInfoObj">
    <v-form ref="form" v-model="valid" v-on:submit.prevent="emitToParentNext">
      <v-row class="form-item justify-center">
        <v-col cols="12" md="2">
          <label for="Academic-Year-name">
            {{ $t("Academic Year Name") }}</label
          >
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            id="academic-year-name"
            v-model="generalInfoObj.en.name"
            :rules="requiredRuleName"
            @focus="emitToParentFocus"
            solo
            dense
          ></v-text-field>

          <div class="error-sec" v-if="validations.en">
            <p class="error-txt red--text">{{ validations.en.name[0] }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row class="form-item justify-center">
        <v-col cols="12" md="2">
          <label for="Academic-Year-name"> {{ $t("Short Name") }}</label>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            id="academic-year-shortname"
            v-model="generalInfoObj.short_name"
            :rules="requiredRuleShortName"
            @focus="emitToParentFocus"
            solo
            dense
          ></v-text-field>

          <div class="error-sec" v-if="validations.short_name">
            <p class="error-txt red--text">
              {{ validations.short_name[0] }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="form-item justify-center">
        <v-col cols="12" md="2">
          <label for="Academic-Year-name"> {{ $t("Start Date") }}</label>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            id="start_date"
            v-model="generalInfoObj.start_date"
            :rules="[
              startDateRule(generalInfoObj.start_date, generalInfoObj.end_date),
              requiredRules.required,
            ]"
            append-icon="calendar_today"
            autocomplete="off"
            solo
            dense
            @keydown.prevent
          ></v-text-field>
          <!-- <div
            class="error--text"
            v-if="
              startDateRule(generalInfoObj.start_date, generalInfoObj.end_date)
            "
          >
            Start date should be less than end date
          </div> -->
        </v-col>
      </v-row>
      <v-row class="form-item justify-center">
        <v-col cols="12" md="2">
          <label for="Academic-Year-name"> {{ $t("End Date") }}</label>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            id="end_date"
            v-model="generalInfoObj.end_date"
            :rules="[
              startDateRule(generalInfoObj.start_date, generalInfoObj.end_date),
              requiredRules.required,
            ]"
            append-icon="calendar_today"
            autocomplete="off"
            solo
            dense
            @keydown.prevent
          ></v-text-field>
          <!-- <div
            class="error--text"
            v-if="
              startDateRule(generalInfoObj.start_date, generalInfoObj.end_date)
            "
          >
            End date should be more than start date
          </div> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5">
          <v-checkbox
            class="mx-2"
            v-model="generalInfoObj.is_addmission_open"
            :label="$t('Admission for this year is open')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="text-right">
          <button class="ui-btn submit">
            {{ $t("Next") }}
          </button>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "GeneralInfo",
  props: {
    clonedId: String,
    clonedObject: {},
    id: Number,
    previous: Boolean,
    dataSaved: Boolean,
    validation: Object,
    userData: Object,
    visited: Boolean, // this to check if component visited or not
    validations: Object,
  },
  data() {
    return {
      valid: true,
      requiredRules: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
      },
      generalInfoObj: {
        short_name: "",
        start_date: "",
        end_date: "",
        en: { name: "" },
        ar: { name: "" },
        is_addmission_open: "",
        type: "year",
      },
      validation1: [],
      // startDateRule: [
      //   // v => !!v || "This field is required",
      //   before(generalInfoObj.end_date, v) {
      //   return v => v < generalInfoObj.end_date  || 'start date must be before end date';
      //   }

      // ]
      requiredRuleName: [
        (v) => !!v || this.$i18n.t("This field is required"),
        (v) =>
          v.length <= 50 ||
          this.$i18n.t("Name must be less than 50 characters"),
      ],
      requiredRuleShortName: [
        (v) => !!v || this.$i18n.t("This field is required"),
        (v) =>
          v.length <= 6 ||
          this.$i18n.t("Short name must be less than 6 characters"),
      ],
    };
  },
  watch: {
    clonedObject: {
      handler() {
        if (this.visited == true && this.dataSaved == true) {
          this.generalInfoObj.short_name = this.userData.short_name;
          this.generalInfoObj.en.name = this.userData.name;
          this.generalInfoObj.start_date = this.userData.start_date;
          this.generalInfoObj.end_date = this.userData.end_date;
          this.generalInfoObj.is_addmission_open = this.userData.is_addmission_open;
        } else if (this.visited == true && this.dataSaved == false) {
          this.generalInfoObj.short_name = this.userData.short_name;
          this.generalInfoObj.en.name = this.userData.en.name;
          this.generalInfoObj.start_date = this.userData.start_date;
          this.generalInfoObj.end_date = this.userData.end_date;
          this.generalInfoObj.is_addmission_open = this.clonedObject.is_addmission_open;
        } else {
          this.generalInfoObj.short_name = this.clonedObject.short_name;
          this.generalInfoObj.en.name = this.clonedObject.name;
          this.generalInfoObj.start_date = "";
          this.generalInfoObj.end_date = "";
          this.generalInfoObj.is_addmission_open = this.clonedObject.is_addmission_open;
        }
      },
    },
  },
  methods: {
    emitToParentNext() {
      if (this.valid == true) {
        var nextObject = {
          tab: 2,
          next: true,
          submittedObject: this.generalInfoObj,
          dataSaved: this.dataSaved,
        };
        this.$emit("childToParentNext", nextObject);
      } else {
        this.$refs.form.validate();
      }
    },
    emitToParentFocus() {
      this.$emit("childToParentEmptyValidation");
    },
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate >= endDate
        ? "Start date should be less than end date"
        : true;
    },
  },

  mounted() {
    var _this = this;
    $(function() {
      $("#start_date").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function(date) {
          if (date[0]) {
            var dateVal =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.generalInfoObj.start_date = dateVal;
          } else {
            _this.generalInfoObj.start_date = "";
          }
        },
      });
      $("#end_date").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function(date) {
          if (date[0]) {
            var dateVal =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.generalInfoObj.end_date = dateVal;
          } else {
            _this.generalInfoObj.end_date = "";
          }
        },
      });
    });

    if (this.visited == true && this.dataSaved == true) {
      this.generalInfoObj.short_name = this.userData.short_name;
      this.generalInfoObj.en.name = this.userData.name;
      this.generalInfoObj.start_date = this.userData.start_date;
      this.generalInfoObj.end_date = this.userData.end_date;
      this.generalInfoObj.is_addmission_open = this.userData.is_addmission_open;
    } else if (this.visited == true && this.dataSaved == false) {
      this.generalInfoObj.short_name = this.userData.short_name;
      this.generalInfoObj.en.name = this.userData.en.name;
      this.generalInfoObj.start_date = this.userData.start_date;
      this.generalInfoObj.end_date = this.userData.end_date;
      this.generalInfoObj.is_addmission_open = this.clonedObject.is_addmission_open;
    } else {
      this.generalInfoObj.short_name = this.clonedObject.short_name;
      this.generalInfoObj.en.name = this.clonedObject.name;
      this.generalInfoObj.start_date = "";
      this.generalInfoObj.end_date = "";
      this.generalInfoObj.is_addmission_open = this.clonedObject.is_addmission_open;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";
#generalInfo {
  margin-top: 100px;
}

.ui-btn {
  margin: 0 10px 0;
}

label {
  margin-top: 7px !important;
}

.error-txt {
  font-size: 0.875rem !important;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .info {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
