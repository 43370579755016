<template>
  <div id="grades">
    <v-form ref="form" v-model="valid">
      <v-data-table
        :headers="headers"
        :items="gradesData"
        :items-per-page="30"
        :no-data-text="t('No grades available')"
        class="elevation-1 level1 newChild padding"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fast_rewind',
          lastIcon: 'fast_forward',
          prevIcon: 'arrow_left',
          nextIcon: 'arrow_right'
        }"
        hide-default-footer
      >
        <template v-slot:item.is_active="{ item }">
          <v-switch v-model="item.is_active"></v-switch>
          <!-- <v-checkbox v-model="item.is_active"></v-checkbox> -->
        </template>
        <template v-slot:item.is_accept_acceptance_age="{ item }">
          <v-switch v-model="item.is_accept_acceptance_age"></v-switch>
          <!-- <v-checkbox v-model="item.is_accept_acceptance_age"></v-checkbox> -->
        </template>

        <template v-slot:item.addmission_age_from="{ item }">
          <v-text-field
            v-model="item.addmission_age_from"
            :id="item.start_date_id"
            :rules="[
              startDateRule(item.addmission_age_from, item.addmission_age_to),
              startDateRequired(
                item.addmission_age_from,
                item.is_accept_acceptance_age
              )
            ]"
            append-icon="calendar_today"
            autocomplete="off"
            solo
            @keydown.prevent
          ></v-text-field>
        </template>
        <template v-slot:item.addmission_age_to="{ item }">
          <v-text-field
            :id="item.end_date_id"
            v-model="item.addmission_age_to"
            :rules="[
              startDateRule(item.addmission_age_from, item.addmission_age_to),
              startDateRequired(
                item.addmission_age_to,
                item.is_accept_acceptance_age
              )
            ]"
            append-icon="calendar_today"
            autocomplete="off"
            solo
            @keydown.prevent
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click="deleteItem(item.grade_id, item.id)">delete</v-icon>
        </template>
      </v-data-table>
    </v-form>

    <div class="icon-sec text-center padding-top">
      <!-- v-can="'add-academic-period-grades'" -->
      <v-icon
        large
        title="Add new item"
        class="mr-2 main-color"
        @click="openDialog"
        >add_circle</v-icon
      >
    </div>
    <v-dialog v-model="dialog" persistent max-width="350px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Add Grade") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <table class="table table-striped">
                  <tr v-for="(grade, index) in grades" :key="index">
                    <td>
                      <v-checkbox
                        v-model="selectedGrades"
                        :value="grade.id"
                      ></v-checkbox>
                    </td>
                    <td>{{ grade.name }}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="modal-btn-cancel" @click="close">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn class="modal-btn-save" text @click="save">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="6" md="6" class="text-left">
        <button class="ui-btn submit" @click="emitToParentPrevious">
          {{ $t("Previous") }}
        </button>
      </v-col>
      <v-col cols="6" md="6" class="text-right">
        <button class="ui-btn submit" @click="emitToParentNext">
          {{ $t("Next") }}
        </button>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Grades",
  props: {
    clonedId: String,
    clonedObject: Array,
    id: Number,
    previous: Boolean,
    dataSaved: Boolean,
    visited: Boolean, // this to check if component visited or not
    userData: Array
  },
  data() {
    return {
      requiredRules: {
        required: v => !!v || this.$i18n.t("This field is required")
      },
      valid: true,
      /* snackbar data */
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      multiline: true,
      updated: false,
      deleted: false,
      gradeError: false,

      headers: [
        {
          text: this.$i18n.t("Grade"),
          align: "left",
          sortable: false,
          value: "grade_name"
        },
        {
          text: this.$i18n.t("Admission is open"),
          value: "is_active",
          sortable: false
        },
        {
          text: this.$i18n.t("Allow acceptance age"),
          value: "is_accept_acceptance_age",
          sortable: false
        },
        {
          text: this.$i18n.t("Birth date From"),
          value: "addmission_age_from",
          sortable: false
        },
        {
          text: this.$i18n.t("Birth date To"),
          value: "addmission_age_to",
          sortable: false
        },
        { text: this.$i18n.t("Action"), value: "actions", sortable: false }
      ],
      gradesData: [],
      grades: [],
      selectedGrades: [],
      /* add item model */
      dialog: false,
      editedItem: {
        grade_name: "",
        grade_id: "",
        is_active: false,
        is_accept_acceptance_age: false,
        addmission_age_from: "",
        addmission_age_to: ""
      }
    };
  },
  watch: {
    clonedObject: {
      handler() {
        if (this.visited == true) {
          this.gradesData = this.userData;
        } else {
          this.gradesData = this.clonedObject;
        }
        this.getSelectedItems();
        this.initiateDatepicker();
      }
    }
  },
  methods: {
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate > endDate
        ? "Birthdate from should be less than birthdate to"
        : true;
    },
    startDateRequired(date, admissionOpen) {
      return admissionOpen == true && date == null
        ? this.$i18n.t("This field is required")
        : true;
    },
    emitToParentNext() {
      if (this.valid == true) {
        var nextObject = {
          tab: 4,
          next: true,
          submittedObject: this.gradesData,
          dataSaved: this.dataSaved
        };
        this.$emit("childToParentNext", nextObject);
      } else {
        this.$refs.form.validate();
      }
    },
    emitToParentPrevious() {
      var previousObject = {
        tab: 2,
        userObject: this.gradesData
      };

      this.$emit("childToParentPrevious", previousObject);
    },

    getGrades() {
      axios.get(this.getApiUrl + "/getGrades").then(response => {
        this.grades = response.data.data.grades;
      });
    },

    deleteItem(gradeId, id) {
      if (this.dataSaved == true && id) {
        axios
          .get(this.getApiUrl + "/school/deleteAcademicPeriodGrade/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            if (response.data.status.error == false) {
              var gradesArray = this.gradesData;
              var selectedGrades = this.selectedGrades;

              gradesArray = gradesArray.filter(function(grade) {
                return grade.grade_id != gradeId;
              });

              selectedGrades = selectedGrades.filter(function(item) {
                return item != gradeId;
              });

              this.selectedGrades = selectedGrades;
              this.gradesData = gradesArray;
            }
          });
      } else {
        var gradesArray = this.gradesData;
        var selectedGrades = this.selectedGrades;

        gradesArray = gradesArray.filter(function(grade) {
          return grade.grade_id != gradeId;
        });

        selectedGrades = selectedGrades.filter(function(item) {
          return item != gradeId;
        });

        this.selectedGrades = selectedGrades;
        this.gradesData = gradesArray;
      }
    },

    initiateDatepicker() {
      var _this = this;
      var tableData = this.gradesData;
      // start date
      tableData.forEach(function(row, i) {
        var startId = "#" + row.start_date_id;
        var endId = "#" + row.end_date_id;
        $(function() {
          $(startId).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var start =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.gradesData[i].addmission_age_from = start;
              } else {
                _this.gradesData[i].addmission_age_from = "";
              }
            }
          });

          $(endId).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var start =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.gradesData[i].addmission_age_to = start;
              } else {
                _this.gradesData[i].addmission_age_to = "";
              }
            }
          });
        });
      });
    },

    openDialog() {
      this.dialog = true;
    },

    getSelectedItems() {
      var selected = this.gradesData;
      var gradeIds = [];
      selected.forEach(function(item) {
        gradeIds.push(item.grade_id);
      });

      this.selectedGrades = gradeIds;
    },

    save() {
      var selectedGrades = this.selectedGrades;
      var tableData = this.gradesData;
      var grades = this.grades;
      var academic_period_id = this.id;
      var saved = this.dataSaved;
      var apiUrl = this.getApiUrl;
      // when user add data on modal
      selectedGrades.forEach(function(item) {
        // eslint-disable-next-line no-empty
        if (tableData.find(row => row.grade_id == item)) {
        } else {
          let gradeName = grades.find(grade => grade.id == item).name;

          // get empty object
          let defaultItem = {
            id: "",
            academic_period_id: "",
            grade_name: "",
            grade_id: "",
            is_active: false,
            is_accept_acceptance_age: false,
            addmission_age_from: "",
            addmission_age_to: "",
            end_date_id: "",
            start_date_id: ""
          };

          defaultItem.academic_period_id = academic_period_id;
          defaultItem.grade_name = gradeName;
          defaultItem.grade_id = item;
          defaultItem.start_date_id = "From" + item;
          defaultItem.end_date_id = "To" + item;

          tableData.push(defaultItem);
        }
      });
      // this.gradesData = tableData;

      // when user delete data from selected
      tableData.forEach(function(row) {
        if (selectedGrades.includes(row.grade_id) == false) {
          if (saved == true && row.id) {
            axios
              .get(apiUrl + "/school/deleteAcademicPeriodGrade/" + row.id, {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              })
              .then(response => {
                // eslint-disable-next-line no-empty
                if (response.data.status.error == false) {
                }
              });
            tableData = tableData.filter(function(grade) {
              return grade.grade_id != row.grade_id;
            });
          } else {
            tableData = tableData.filter(function(grade) {
              return grade.grade_id != row.grade_id;
            });
          }
        }
      });
      this.gradesData = tableData;

      this.initiateDatepicker();
      this.dialog = false;
    },

    close() {
      this.dialog = false;
      this.getSelectedItems();
    }
  },
  mounted() {
    if (this.visited == true) {
      this.gradesData = this.userData;
    } else {
      this.gradesData = this.clonedObject;
    }

    this.getSelectedItems();
    this.getGrades();
    this.initiateDatepicker();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.ui-btn {
  margin: 0 10px 0;
}

label {
  margin-top: 7px !important;
}
.padding {
  padding-top: 100px;
}

.table-striped tr:nth-of-type(odd) {
  background-color: #f3f6ff !important;
}

.table-striped td {
  padding: 5px 5px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

// #grades {
//   margin-top: 100px;
// }
</style>
