<template>
  <div id="academicPeriodsClone" v-if="academicPeriods">
    <v-form ref="form" v-model="valid">
      <v-row v-for="(level2, index) in academicPeriods" :key="index">
        <v-col cols="12" sm="12">
          <v-card class="mx-auto">
            <v-card-text>
              <v-row class="form-item">
                <v-col cols="12" md="3" style="margin-top:15px !important;">
                  <label for="Academic-Year-name"> {{ $t("Name") }} </label>
                </v-col>
                <v-col cols="12" md="8" style="margin-top:15px !important;">
                  <v-text-field
                    id="academic-year-name"
                    v-model="level2.translations[1].name"
                    :rules="requiredRuleName"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-item">
                <v-col cols="12" md="3">
                  <label for="Academic-Year-name">
                    {{ $t("Short Name") }}</label
                  >
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    id="academic-year-name"
                    v-model="level2.short_name"
                    :rules="requiredRuleShortName"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-item">
                <v-col cols="12" md="3">
                  <label for="Academic-Year-name">
                    {{ $t("Start Date") }}</label
                  >
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :id="level2.start_date_id"
                    :rules="[
                      startDateRule(level2.start_date, level2.end_date),
                      requiredRules.required
                    ]"
                    v-model="level2.start_date"
                    append-icon="calendar_today"
                    solo
                    dense
                    autocomplete="off"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-item">
                <v-col cols="12" md="3">
                  <label for="Academic-Year-name"> {{ $t("End Date") }}</label>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :id="level2.end_date_id"
                    :rules="[
                      startDateRule(level2.start_date, level2.end_date),
                      requiredRules.required
                    ]"
                    v-model="level2.end_date"
                    append-icon="calendar_today"
                    solo
                    dense
                    autocomplete="off"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          v-for="(level3, i) in level2.grandchildren"
          :key="i"
        >
          <v-card class="mx-auto">
            <v-card-text>
              <v-row class="form-item">
                <v-col cols="12" md="3" style="margin-top:15px !important;">
                  <label for="Academic-Year-name"> {{ $t("Name") }}</label>
                </v-col>
                <v-col cols="12" md="9" style="margin-top:15px !important;">
                  <v-text-field
                    id="academic-year-name"
                    v-model="level3.translations[1].name"
                    :rules="requiredRuleName"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-item">
                <v-col cols="12" md="3">
                  <label for="Academic-Year-name">
                    {{ $t("Short Name") }}</label
                  >
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    id="academic-year-name"
                    v-model="level3.short_name"
                    :rules="requiredRuleShortName"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-item">
                <v-col cols="12" md="3">
                  <label for="Academic-Year-name">
                    {{ $t("Start Date") }}</label
                  >
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    :id="level3.start_date_id"
                    :rules="[
                      startDateRule(level3.start_date, level3.end_date),
                      requiredRules.required
                    ]"
                    v-model="level3.start_date"
                    append-icon="calendar_today"
                    solo
                    dense
                    autocomplete="off"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-item">
                <v-col cols="12" md="3">
                  <label for="Academic-Year-name"> {{ $t("End Date") }}</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    :id="level3.end_date_id"
                    :rules="[
                      startDateRule(level3.start_date, level3.end_date),
                      requiredRules.required
                    ]"
                    v-model="level3.end_date"
                    append-icon="calendar_today"
                    solo
                    dense
                    autocomplete="off"
                    @keydown.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" md="10">
        <v-alert class="info_alert" v-if="academicPeriods.length == 0">
          {{ $t("There are no academic periods for this year") }}
        </v-alert>
      </v-col>
      <v-col cols="6" md="6" class="text-left">
        <button class="ui-btn submit" @click="emitToParentPrevious">
          {{ $t("Previous") }}
        </button>
      </v-col>
      <v-col cols="6" md="6" class="text-right">
        <button class="ui-btn submit" @click="emitToParentNext">
          {{ $t("Next") }}
        </button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "AcademicPeriods",
  props: {
    clonedId: String,
    clonedObject: {},
    id: Number,
    previous: Boolean,
    dataSaved: Boolean,
    visited: Boolean, // this to check if component visited or not
    userData: Array
  },
  data() {
    return {
      valid: true,
      academicPeriods: [],
      requiredRules: {
        required: v => !!v || this.$i18n.t("This field is required")
      },
      requiredRuleName: [
        v => !!v || this.$i18n.t("This field is required"),
        v =>
          v.length <= 50 || this.$i18n.t("Name must be less than 50 characters")
      ],
      requiredRuleShortName: [
        v => !!v || this.$i18n.t("This field is required"),
        v =>
          v.length <= 6 ||
          this.$i18n.t("Short name must be less than 6 characters")
      ]
    };
  },
  watch: {
    clonedObject: {
      handler() {
        if (this.visited == true) {
          this.academicPeriods = this.userData;
        } else {
          if (this.clonedObject.grandchildren) {
            this.academicPeriods = this.clonedObject.grandchildren;
          } else {
            this.academicPeriods = [];
          }
        }
        this.initiateDatePicker();
      }
    }
  },
  methods: {
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate >= endDate
        ? "Start date should be less than end date"
        : true;
    },
    emitToParentNext() {
      if (this.valid == true) {
        var nextObject = {
          tab: 3,
          next: true,
          submittedObject: this.academicPeriods,
          dataSaved: this.dataSaved
        };
        this.$emit("childToParentNext", nextObject);
      } else {
        this.$refs.form.validate();
      }
    },

    emitToParentPrevious() {
      var previousObject = {
        tab: 1,
        userObject: this.academicPeriods
      };

      this.$emit("childToParentPrevious", previousObject);
    },

    initiateDatePicker() {
      var _this = this;
      var periodsArray = this.academicPeriods;

      periodsArray.forEach(function(level2, i) {
        var startDateId = "#" + level2.start_date_id;
        var endDateId = "#" + level2.end_date_id;

        $(function() {
          $(startDateId).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var start_date =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.academicPeriods[i].start_date = start_date;
              } else {
                _this.academicPeriods[i].start_date = "";
              }
            }
          });

          $(endDateId).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var end_date =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.academicPeriods[i].end_date = end_date;
              } else {
                _this.academicPeriods[i].end_date = "";
              }
            }
          });
        });

        /* level 3 */
        if (level2.grandchildren) {
          var grandChildren = level2.grandchildren;

          if (grandChildren.length > 0) {
            grandChildren.forEach(function(level3, index) {
              var level3StartDateId = "#" + level3.start_date_id;
              var level3EndDateId = "#" + level3.end_date_id;

              $(function() {
                $(level3StartDateId).calendarsPicker({
                  dateFormat: "dd/mm/yyyy",
                  onSelect: function(date) {
                    if (date[0]) {
                      var start_date =
                        date[0]._day +
                        "/" +
                        date[0]._month +
                        "/" +
                        date[0]._year;
                      _this.academicPeriods[i].grandchildren[
                        index
                      ].start_date = start_date;
                    } else {
                      _this.academicPeriods[i].grandchildren[index].start_date =
                        "";
                    }
                  }
                });
                $(level3EndDateId).calendarsPicker({
                  dateFormat: "dd/mm/yyyy",
                  onSelect: function(date) {
                    if (date[0]) {
                      var end_date =
                        date[0]._day +
                        "/" +
                        date[0]._month +
                        "/" +
                        date[0]._year;
                      _this.academicPeriods[i].grandchildren[
                        index
                      ].end_date = end_date;
                    } else {
                      _this.academicPeriods[i].grandchildren[index].end_date =
                        "";
                    }
                  }
                });
              });
            });
          }
        }
      });
    }
  },
  mounted() {
    if (this.visited == true) {
      this.academicPeriods = this.userData;
    } else {
      if (this.clonedObject.grandchildren) {
        this.academicPeriods = this.clonedObject.grandchildren;
      } else {
        this.academicPeriods = [];
      }
    }
    this.initiateDatePicker();
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.info_alert {
  margin-left: 23%;
  margin-top: 10%;
  margin-bottom: 13%;
  color: rgba(0, 0, 0, 0.38);
}

#academicPeriodsClone {
  margin-top: 100px;
}
</style>
