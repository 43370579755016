<template>
  <div id="yearSetup">
    <v-row>
      <v-col cols="12" sm="3">
        <div class="cart-sec">
          <v-data-table
            :headers="gradeHeaders"
            :items="academicSetupData"
            v-model="selecteditem"
            :no-data-text="$t('No grades available')"
            class="elevation-1 level1 newChild grades-table"
            hide-default-footer
            item-key="grade_id"
            @click:row="selectGrade"
            :single-select="true"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="main-color">{{
                  $t("Grades")
                }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12" sm="9">
        <div class="cart-sec">
          <v-data-table
            :headers="headers"
            :items="subjects"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="subject_id"
            show-expand
            class="elevation-1 level1 newChild subjects-table"
            hide-default-footer
            :no-data-text="$t('No subjects available')"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="main-color"
                  >{{ subjectsTitle }} {{ $t("Subjects") }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="books" :colspan="headers.length">
                <v-data-table
                  :headers="booksHeaders"
                  :items="item.books"
                  no-data-text="No books available"
                  class="elevation-1 books-table"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title class="main-color">{{
                        $t("Books")
                      }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template #item.subjectName="{item}">
                    {{ getSubjectName(item.subject_id) }}
                  </template>
                  <template #item.actions="{item}">
                    <v-icon
                      class="main-color"
                      @click="deleteBook(item.subject_id, item)"
                      >delete</v-icon
                    >
                  </template>
                </v-data-table>
                <div class="text-center icon-sec">
                  <v-icon
                    class="main-color"
                    @click="openAddBookModal(item.subject_id)"
                    >add_circle</v-icon
                  >
                </div>
              </td>
            </template>

            <template #item.actions="{item}">
              <v-icon class="main-color" @click="deteSubject(item.subject_id)"
                >delete</v-icon
              >
            </template>
          </v-data-table>
          <div class="text-center icon-sec">
            <v-icon
              class="main-color"
              v-if="subjectsTitle"
              large
              @click="getSubjectsMenu"
              >add_circle</v-icon
            >
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- add subject and add book dialogs -->
    <!-- add subject dialog -->
    <div class="text-center">
      <v-dialog persistent v-model="addDialog" width="400">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("Add Subject") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label>{{ $t("Select Subjects") }}</label>
                  <v-autocomplete
                    v-model="subjectIds"
                    :items="allSubjects"
                    item-text="en.name"
                    item-value="id"
                    chips
                    small-chips
                    multiple
                    solo
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="modal-btn-cancel" @click="cancelSubjectAdd">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="modal-btn-save" @click="addSubject">
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- add Book dialog -->
    <div class="text-center">
      <v-dialog persistent v-model="addBookDialog" width="400">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("Add Book") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="modalForm" v-model="modalValid">
                <v-row>
                  <v-col cols="12">
                    <label>{{ $t("Name") }}</label>
                    <v-text-field
                      v-model="defaultBook.book"
                      :rules="requiredRuleName"
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <label>{{ $t("Author") }}</label>
                    <v-text-field
                      v-model="defaultBook.author"
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="modal-btn-cancel" @click="cancelBookAdd">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="modal-btn-save" @click="addBook(activeSubjectId)">
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <v-col cols="6" md="6" class="text-left">
        <button class="ui-btn submit" @click="emitToParentPrevious">
          {{ $t("Previous") }}
        </button>
      </v-col>
      <v-col cols="6" md="6" class="text-right">
        <!--   -->
        <button class="ui-btn submit" @click="emitToParentNext">
          {{ $t("Finish") }}
        </button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AcademicYearSetup",
  props: {
    clonedId: String,
    clonedObject: Array,
    id: Number,
    previous: Boolean,
    dataSaved: Boolean,
    visited: Boolean, // this to check if component visited or not
    userData: Array
  },
  data() {
    return {
      selecteditem: [],
      requiredRuleName: [
        v => !!v || this.$i18n.t("This field is required"),
        v =>
          v.length <= 50 || this.$i18n.t("Name must be less than 50 characters")
      ],
      modalValid: true,

      gradeHeaders: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "grade_name"
        }
      ],
      booksHeaders: [
        {
          text: this.$i18n.t("Name"),
          align: "start",
          sortable: false,
          value: "book"
        },
        { text: this.$i18n.t("Author"), value: "author", sortable: false },
        // { text: "Title", value: "subjectName", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false }
      ],
      expanded: [],
      singleExpand: true,
      headers: [
        {
          text: this.$i18n.t("Name"),
          align: "start",
          sortable: false,
          value: "subject_name"
        },
        { text: this.$i18n.t("Type"), value: "type", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false }
      ],
      subjects: [],
      subjectsTitle: "",
      activeGradeId: "",
      activeSubjectId: "",
      academicSetupData: [],

      // add subject
      subjectIds: [],
      allSubjects: [],
      addDialog: false,

      // add book
      addBookDialog: false,
      defaultBook: {
        author: "",
        book: "",
        id: "",
        subject_id: ""
      },
      userSelectedGrades: []
    };
  },
  watch: {
    clonedObject: {
      handler() {
        if (this.visited == true) {
          this.academicSetupData = this.userData;
        } else {
          this.academicSetupData = this.clonedObject;
        }
        this.getGradeData();
      }
    }
  },
  methods: {
    emitToParentPrevious() {
      var previousObject = {
        tab: 4,
        userObject: this.academicSetupData
      };
      this.$emit("childToParentPrevious", previousObject);
    },

    emitToParentNext() {
      var nextObject = {
        tab: 6,
        next: true,
        submittedObject: this.academicSetupData,
        dataSaved: this.dataSaved
      };
      this.$emit("childToParentNext", nextObject);
    },

    cancelSubjectAdd() {
      this.addDialog = false;
      this.defaultBook = {
        author: "",
        book: "",
        id: "",
        subject_id: ""
      };
    },

    cancelBookAdd() {
      this.addBookDialog = false;
      this.defaultBook = {
        author: "",
        book: "",
        id: "",
        subject_id: ""
      };
      this.$refs.modalForm.resetValidation();
    },

    addSubject() {
      let subjectIds = this.subjectIds;
      let allSubjects = this.allSubjects;
      let subjects = this.subjects;
      let activeGradeId = this.activeGradeId;
      // var apiUrl = this.getApiUrl;

      // when user add data on modal
      subjectIds.forEach(function(subjectId) {
        if (subjects.find(row => row.subject_id == subjectId)) {
          // console.log("found");
        } else {
          let subjectName = allSubjects.find(sub => sub.id == subjectId).en
            .name;
          let subjectType = allSubjects.find(sub => sub.id == subjectId)
            .subject_type_name;

          let defaultSubject = {
            books: [],
            id: "",
            subject_id: subjectId,
            subject_name: subjectName,
            type: subjectType
          };

          subjects.push(defaultSubject);
        }
      });

      // when user delete data from selected
      subjects.forEach(function(row) {
        if (subjectIds.includes(row.subject_id) == false) {
          subjects = subjects.filter(function(subject) {
            return subject.subject_id != row.subject_id;
          });
        }
      });

      this.subjects = subjects;

      this.academicSetupData.find(
        object => object.grade_id == activeGradeId
      ).subjects = this.subjects;

      this.addDialog = false;
    },

    openAddBookModal(subjectId) {
      this.addBookDialog = true;
      this.activeSubjectId = subjectId;
      this.$refs.modalForm.resetValidation();
    },

    addBook(subjectId) {
      if (this.modalValid == true) {
        let subjects = this.subjects;
        let activeGradeId = this.activeGradeId;
        this.defaultBook.subject_id = subjectId;

        this.subjects
          .find(subject => subject.subject_id == subjectId)
          .books.push(this.defaultBook);

        this.academicSetupData
          .find(object => object.grade_id == activeGradeId)
          .subjects.find(
            subject => subject.subject_id == subjectId
          ).books = subjects.find(
          subject => subject.subject_id == subjectId
        ).books;

        this.defaultBook = {
          author: "",
          book: "",
          id: "",
          subject_id: ""
        };

        this.addBookDialog = false;
      } else {
        this.$refs.modalForm.validate();
      }
    },

    deteSubject(subjectId) {
      let subjectIds = this.subjectIds;
      let subjects = this.subjects;
      let activeGradeId = this.activeGradeId;

      subjects = subjects.filter(function(subject) {
        return subject.subject_id != subjectId;
      });

      subjectIds = subjectIds.filter(function(id) {
        return id != subjectId;
      });

      this.subjects = subjects;
      this.subjectIds = subjectIds;

      this.academicSetupData.find(
        object => object.grade_id == activeGradeId
      ).subjects = this.subjects;
    },

    deleteBook(subjectId, item) {
      let activeGradeId = this.activeGradeId;

      let selectedSubject = this.subjects.find(
        subject => subject.subject_id == subjectId
      );

      const index = selectedSubject.books.indexOf(item);
      selectedSubject.books.splice(index, 1);

      this.subjects.find(subject => subject.subject_id == subjectId).books =
        selectedSubject.books;

      this.academicSetupData
        .find(object => object.grade_id == activeGradeId)
        .subjects.find(subject => subject.subject_id == subjectId).books =
        selectedSubject.books;
    },

    selectGrade(value) {
      this.selecteditem = [];
      this.selecteditem.push(value);
      this.subjectsTitle = value.grade_name;
      this.activeGradeId = value.grade_id;
      this.subjects = value.subjects;
    },

    getSubjectName(subjectId) {
      return this.subjects.find(subject => subject.subject_id == subjectId)
        .subject_name;
    },

    getSubjectsMenu() {
      this.addDialog = true;
      // subjects
      axios
        .get(this.getApiUrl + "/subjects?edit=true", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.allSubjects = response.data.data;

          let allSub = this.subjects;
          let ids = [];
          allSub.forEach(function(subject) {
            ids.push(subject.subject_id);
          });

          this.subjectIds = ids;
        });
    },

    getGradeData() {
      axios
        .get(
          this.getApiUrl +
            "/school/getAcademicPeriodGrade/" +
            this.id +
            "?edit=true",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
              //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == false) {
            this.userSelectedGrades = response.data.data;
            this.filterGradesData();
          }
        });
    },

    filterGradesData() {
      let gradesDataArray = this.academicSetupData;
      let usersGrades = this.userSelectedGrades;
      let gradesIds = [];
      let defaultObject = {
        grade_id: "",
        grade_name: "",
        id: "",
        subjects: []
      };

      // get all userIds
      usersGrades.forEach(function(grade) {
        gradesIds.push(grade.grade_id);
      });

      // delete data from table is grade not exist
      gradesDataArray.forEach(function(row) {
        if (gradesIds.includes(row.grade_id) == false) {
          gradesDataArray = gradesDataArray.filter(function(gradeObject) {
            return gradeObject.grade_id != row.grade_id;
          });
        }
      });

      // add data to table when not exist
      gradesIds.forEach(function(gradeId) {
        // eslint-disable-next-line no-empty
        if (gradesDataArray.find(row => row.grade_id == gradeId)) {
        } else {
          let gradeName = usersGrades.find(grade => grade.grade_id == gradeId)
            .grade_name;

          // get gradeObject id
          let objectId = usersGrades.find(grade => grade.grade_id == gradeId)
            .id;

          defaultObject.id = objectId;
          defaultObject.grade_name = gradeName;
          defaultObject.grade_id = gradeId;

          gradesDataArray.push(defaultObject);
        }

        defaultObject = {
          grade_id: "",
          grade_name: "",
          id: "",
          subjects: []
        };
      });

      this.academicSetupData = gradesDataArray;

      if (this.academicSetupData.length > 0) {
        this.selectGrade(this.academicSetupData[0]);
      }
    }
  },
  mounted() {
    if (this.visited == true) {
      this.academicSetupData = this.userData;
    } else {
      this.academicSetupData = this.clonedObject;
    }
    this.getGradeData();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

#yearSetup {
  margin-top: 100px;
}

.icon-sec {
  margin: 10px 0;
}
</style>
