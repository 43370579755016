<template>
  <div id="cloneAcademic">
    <div class="header">
      <h2 class="main-color">{{ $t("Academic Year Setup") }}</h2>
      <hr />
    </div>
    <div class="prog-content padding-top">
      <ul class="progressbar">
        <li :class="{ active: tab >= 1 }" id="step1">
          <span class="icon"><i class="fas fa-info"></i> </span>
          <span class="text">{{ $t("General Information") }}</span>
        </li>
        <li id="step2" :class="{ active: tab >= 2 }">
          <span class="icon">
            <i class="far fa-calendar"></i>
          </span>
          <span class="text">{{ $t("Academic Periods") }}</span>
        </li>
        <li id="step3" :class="{ active: tab >= 3 }">
          <span>
            <v-icon class="icon">local_library</v-icon>
          </span>
          <span class="text">{{ $t("Grades") }}</span>
        </li>
        <li id="step4" :class="{ active: tab >= 4 }">
          <span class="icon"><i class="fas fa-user-graduate"></i></span>
          <span class="text">
            {{ $t("Classes") }}
          </span>
        </li>
        <li id="step5" :class="{ active: tab >= 5 }">
          <span class="icon"
            ><img
              src="../../assets/icons/subjects-white.png"
              class="subject-icon"
              alt=""
          /></span>
          <span class="text">{{ $t("Subjects") }}</span>
        </li>
      </ul>
    </div>

    <div class="loader text-center" v-if="!loaded">
      <img src="../../assets/Spinner-1s-200px.gif" alt="" />
    </div>

    <GeneralInfo
      v-if="tab == 1 && loaded"
      :clonedId="AcademicPeriodsClonedId"
      :clonedObject="AcademicPeriodsClonedObj"
      :id="id"
      :userData="CopyObject1"
      :previous="previous"
      :dataSaved="tab1Saved"
      :visited="visited1"
      v-on:childToParentNext="onChildClickNext"
      v-on:childToParentEmptyValidation="onChild1Focus"
      :validations="validationsTab1"
    ></GeneralInfo>

    <AcademicPeriods
      v-if="tab == 2 && loaded"
      :clonedId="AcademicPeriodsClonedId"
      :clonedObject="AcademicPeriodsClonedObj"
      :id="id"
      :dataSaved="tab2Saved"
      :visited="visited2"
      :userData="CopyObject2"
      :previous="previous"
      v-on:childToParentNext="onChildClickNext"
      v-on:childToParentPrevious="onChildClickPrevious"
    ></AcademicPeriods>

    <Grades
      v-if="tab == 3 && loaded"
      :clonedId="AcademicPeriodsClonedId"
      :clonedObject="grades"
      :id="id"
      :visited="visited3"
      :dataSaved="tab3Saved"
      :userData="CopyObject3"
      :previous="previous"
      v-on:childToParentNext="onChildClickNext"
      v-on:childToParentPrevious="onChildClickPrevious"
    ></Grades>
    <Classes
      v-if="tab == 4 && loaded"
      :clonedId="AcademicPeriodsClonedId"
      :clonedObject="classes"
      :id="id"
      :dataSaved="tab4Saved"
      :userData="CopyObject4"
      :visited="visited4"
      :previous="previous"
      v-on:childToParentNext="onChildClickNext"
      v-on:childToParentPrevious="onChildClickPrevious"
    ></Classes>

    <AcademicYearSetup
      v-if="tab == 5 && loaded"
      :clonedId="AcademicPeriodsClonedId"
      :clonedObject="gradesSubjectsBooks"
      :id="id"
      :dataSaved="tab5Saved"
      :userData="CopyObject5"
      :visited="visited5"
      :previous="previous"
      v-on:childToParentNext="onChildClickNext"
      v-on:childToParentPrevious="onChildClickPrevious"
    ></AcademicYearSetup>

    <v-snackbar v-model="snackbar" :color="color" :top="true" :right="true">
      {{ text }}
      <v-btn color="red" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import GeneralInfo from "../../components/cloneAcademicPeriodsComponents/GeneralInfo";
import AcademicPeriods from "../../components/cloneAcademicPeriodsComponents/AcademicPeriods";
import Grades from "../../components/cloneAcademicPeriodsComponents/GradesClone";
import Classes from "../../components/cloneAcademicPeriodsComponents/ClassesClone";
import AcademicYearSetup from "../../components/cloneAcademicPeriodsComponents/AcademicYearSetup";

export default {
  name: "cloneAcademicPeriods",
  components: {
    GeneralInfo,
    AcademicPeriods,
    Grades,
    Classes,
    AcademicYearSetup,
  },
  data() {
    return {
      tab: 1,
      loaded: false,
      next: "",
      previous: false,
      id: 0,
      // check if tab saved or not
      tab1Saved: false,
      tab2Saved: false,
      tab3Saved: false,
      tab4Saved: false,
      tab5Saved: false,

      // check if tab visited or not
      visited1: false,
      visited2: false,
      visited3: false,
      visited4: false,
      visited5: false,

      //user data object
      CopyObject1: {},
      CopyObject2: [],
      CopyObject3: [],
      CopyObject4: [],
      CopyObject5: [],

      AcademicPeriodsClonedId: "",
      AcademicPeriodsClonedObj: {},
      grades: [],
      classes: [],
      gradesSubjectsBooks: [],
      snackbar: false,
      text: "",
      color: "",
      validation: [],
      validationsTab1: {},
    };
  },
  methods: {
    getAcademicPeriodObj() {
      axios
        .get(
          this.getApiUrl +
            "/school/showAcademicPeriod/" +
            this.AcademicPeriodsClonedId +
            "?edit=true",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.AcademicPeriodsClonedObj = response.data.data;
          this.loaded = true;
        });
    },

    getGradeData() {
      var id = "";
      if (this.tab3Saved == true) {
        id = "/" + this.id;
      } else {
        id = "/" + this.AcademicPeriodsClonedId;
      }

      axios
        .get(
          this.getApiUrl + "/school/getAcademicPeriodGrade" + id + "?edit=true",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.grades = response.data.data;
          this.loaded = true;
        });
    },
    getClassesObj() {
      var id = "";
      if (this.tab4Saved == true) {
        id = "/" + this.id;
      } else {
        id = "/" + this.AcademicPeriodsClonedId;
      }

      axios
        .get(this.getApiUrl + "/classes/nested" + id + "?all_data", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.classes = response.data.data;
          this.loaded = true;
        });
    },
    getGradesSubjectsBooks() {
      axios
        .get(
          this.getApiUrl +
            "/school/showGradesSubjectsBooksInYearCloned/" +
            this.AcademicPeriodsClonedId +
            "/" +
            this.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.gradesSubjectsBooks = response.data.data;
          this.loaded = true;
        });
    },

    onChildClickNext(value) {
      // next and save for tab1
      if (value.tab == 2 && value.next == true) {
        this.loaded = false;

        var id = "";
        if (this.tab1Saved == true) {
          id = "/" + this.id;
        } else {
          id = "";
        }

        // value.push();
        // Object.assign(value, { clone: true });
        value.submittedObject.clone = true;
        axios
          .post(
            this.getApiUrl + "/school/registerAcademicPeriod" + id,
            value.submittedObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == true) {
              this.text = response.data.status.message;
              this.snackbar = true;
              this.color = "error";
              this.validationsTab1 = response.data.status.validation_errors;
              this.tab = 1;
              this.loaded = true;
              this.visited1 = true;
              this.CopyObject1 = value.submittedObject;
            } else {
              this.id = response.data.data.id;
              this.CopyObject1 = response.data.data;
              this.tab1Saved = true;
              this.visited1 = true;
              if (
                value.submittedObject.is_addmission_open == 1 ||
                value.submittedObject.is_addmission_open == true
              ) {
                axios
                  .post(
                    this.getApiUrl + "/school/ChangeAdmissionOnYear/" + this.id,
                    { is_addmission_open: 1 },
                    {
                      headers: {
                        Authorization: "Bearer " + localStorage.token,
                      },
                    }
                  )
                  .then((response) => {
                    if (response.data.status.error == true) {
                      this.text = response.data.status.message;
                      this.snackbar = true;
                      this.color = "error";
                      this.validation = response.data.status.validation_errors;
                      value.submittedObject.is_addmission_open = 0;
                    } else {
                      this.text = response.data.status.message;
                      this.snackbar = true;
                      this.color = "success";
                      value.submittedObject.is_addmission_open = 1;
                      this.CopyObject1.is_addmission_open = 1;
                      this.tab = value.tab;
                      this.previous = false;
                      this.next == true;
                      this.tab1Saved = true;
                      this.visited1 = true;
                      this.loaded = true;
                    }
                  });
              } else {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "success";

                this.tab = value.tab;
                this.next == true;
                this.previous = false;
                this.tab1Saved = true;
                this.visited1 = true;
              }

              // check if next tab saved before or not
              if (this.tab2Saved == false) {
                this.getAcademicPeriodObj();
              } else {
                this.loaded = true;
              }
            }
          });
      }
      // next and save for tab2
      if (value.tab == 3 && value.next == true) {
        this.tab = value.tab;
        this.loaded = false;

        if (this.tab2Saved == true) {
          axios
            .post(
              this.getApiUrl + "/school/academicPeriods/update/" + this.id,
              { grandchildren: value.submittedObject },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "error";
              } else {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "success";

                this.tab = value.tab;
                this.next = true;
                this.previous = false;
                // this.id = response.data.data.id;
                this.tab2Saved = true;
                this.visited2 = true;

                if (this.tab3Saved == false) {
                  this.getGradeData();
                } else {
                  this.loaded = true;
                }
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/school/academicPeriods/store/" + this.id,
              { grandchildren: value.submittedObject },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "error";
              } else {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "success";

                this.tab = value.tab;
                this.next = true;
                this.previous = false;
                // this.id = response.data.data.id;
                this.tab2Saved = true;
                this.visited2 = true;

                if (this.tab3Saved == false) {
                  this.getGradeData();
                } else {
                  this.loaded = true;
                }
              }
            });
        }
      }
      // next and save for tab3
      if (value.tab == 4 && value.next == true) {
        this.loaded = false;
        if (this.tab3Saved == true) {
          axios
            .post(
              this.getApiUrl + "/school/grades/updateCloned/" + this.id,
              { academicPeriods: value.submittedObject },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "error";
              } else {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "success";
                this.tab3Saved = true;
                this.CopyObject3 = response.data.data;
                this.visited3 = true;
                this.tab = 4;
                this.loaded = true;
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/school/grades/storeCloned/" + this.id,
              { academicPeriods: value.submittedObject },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "error";
              } else {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "success";
                this.tab3Saved = true;
                this.visited3 = true;
                this.CopyObject3 = response.data.data;
                this.tab = 4;
                this.loaded = true;
              }
            });
        }
      }
      //next and save for tab4
      if (value.tab == 5 && value.next == true) {
        this.loaded = false;
        if (this.tab4Saved == true) {
          axios
            .post(
              this.getApiUrl + "/classes/updateNested/" + this.id,
              { classes: value.submittedObject },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "error";
              } else {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "success";
                this.tab4Saved = true;
                this.visited4 = true;
                this.CopyObject4 = response.data.data;

                this.tab = 5;
                this.getGradesSubjectsBooks();
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/classes/storeNested/" + this.id,
              { classes: value.submittedObject },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "error";
              } else {
                this.text = response.data.status.message;
                this.snackbar = true;
                this.color = "success";
                this.tab4Saved = true;
                this.visited4 = true;
                this.CopyObject4 = response.data.data;
                this.tab = 5;
                this.getGradesSubjectsBooks();
              }
            });
        }
      }

      if (value.tab == 6 && value.next == true) {
        axios
          .post(
            this.getApiUrl +
              "/school/storeGradesSubjectsBooksInYearCloned/" +
              this.id,
            { data: value.submittedObject },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            if (response.data.status.error == true) {
              this.tab = 5;
              this.text = response.data.status.message;
              this.snackbar = true;
              this.color = "error";
            } else {
              this.$router.push("/academics?page=1");
            }
          });
      }
    },

    onChildClickPrevious(value) {
      this.tab = value.tab;

      if (this.tab == 1) {
        this.visited2 = true;
        this.CopyObject2 = value.userObject;
      }

      if (this.tab == 2) {
        this.visited3 = true;
        this.CopyObject3 = value.userObject;
      }

      if (this.tab == 3) {
        this.visited4 = true;
        this.CopyObject4 = value.userObject;
      }
      if (this.tab == 4) {
        this.visited5 = true;
        this.CopyObject5 = value.userObject;
      }

      axios
        .get(this.getApiUrl + "/school/showAcademicPeriod/" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.AcademicPeriodsClonedObj = response.data.data;

          this.previous = true;
          this.next = false;
        });
    },

    onChild1Focus() {
      this.validationsTab1 = [];
    },
  },
  mounted() {
    this.AcademicPeriodsClonedId = this.$router.currentRoute.params.id;
    this.getAcademicPeriodObj();
    this.getClassesObj();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.prog-content {
  width: 100%;
  z-index: 1;
}

.header h2 {
  font-size: 1.5rem;
}

.progressbar {
  li {
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
  }

  li .icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    border: 1px solid $main-gray;
    display: block;
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    background-color: $main-gray;
    color: #ffffff;
    position: relative;
    z-index: 11;
  }

  li .text {
    margin-top: 10px;
    display: inline-block;
  }

  li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $main-gray;
    top: 23px;
    left: -50%;
    z-index: 1;
  }
  li:first-child:after {
    content: none;
  }
  li.active::after {
    background-color: $main-color;
  }

  li.active {
    color: $main-color;
  }
  li.active .icon {
    border: 1px solid $main-color;
    background-color: $main-color;
    color: #ffffff;
  }
}

@media (max-width: 600px) {
  .progressbar li .icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  li .text {
    display: none !important;
  }

  li.active .text {
    display: inline-block !important;
    font-size: 10px !important;
  }
  .loader img {
    margin-top: 50px;
    display: inline-block;
    margin-right: 2rem;
  }
}

.subject-icon {
  max-width: 30px !important;
}
</style>
